@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Creepster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Limelight&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap);
@font-face {
  font-family: 'NeonTubes2';
  src: local('NeonTubes2'), url(https://app.portlmedia.com/static/media/NeonTubes2.f01f7e70.otf) format('truetype');
}

@font-face {
  font-family: 'FeniceStd';
  src: local('FeniceStd'), url(https://app.portlmedia.com/static/media/FeniceStd-Regular.66e7d1ec.otf) format('truetype');
}

@font-face {
  font-family: 'FeniceStd';
  src: local('FeniceStd'), url(https://app.portlmedia.com/static/media/FeniceStd-Oblique.b3fc3cb1.otf) format('truetype');
  font-style: oblique;
}

@font-face {
  font-family: 'FeniceStd';
  src: local('FeniceStd'), url(https://app.portlmedia.com/static/media/FeniceStd-Bold.602d4e19.otf) format('truetype');
  font-weight: bold;
}
@font-face {
    font-family: 'CryUncial';
    src: local('CRYUNCIAL-OAOO'), url(https://app.portlmedia.com/static/media/CRYUNCIAL-OAOO.5632d741.TTF) format('truetype');
}
@font-face {
    font-family: 'FuturaPT-Bold';
    src: local('FuturaPT-Bold'), url(https://app.portlmedia.com/static/media/FuturaPT-Bold.30a810fa.ttf) format('truetype');
}
@font-face {
    font-family: 'FuturaPT-Med';
    src: local('FuturaPT-Medium'), url(https://app.portlmedia.com/static/media/FuturaPT-Medium.4ab635fb.ttf) format('truetype');
}
@font-face {
    font-family: 'FuturaPT-Book';
    src: local('FuturaPT-Book'), url(https://app.portlmedia.com/static/media/FuturaPT-Book.16ebcf48.ttf) format('truetype');
}
@font-face {
    font-family: 'FuturaPT-Light';
    src: local('FuturaPT-Light'), url(https://app.portlmedia.com/static/media/FuturaPT-Light.ba102592.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

